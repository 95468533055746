import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "https://nadtech.sokeserver.nadtech.co.ke",
  }),
  //https://nadtech.sokeserver.nadtech.co.ke
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "dashboardStats",
    "Postnewproduct",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `/users/getuser/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => `api/products`,
      providesTags: ["Products"],
    }),
    getProduct: build.query({
      query: (id) => `api/products/${id}`,
      providesTags: ["Product"],
    }),
    getOfferProducts: build.query({
      query: () => `/api/products/product/offer`,
      providesTags: ["Offerproducts"],
    }),
    getClients: build.query({
      query: () => `/clients/getclients`,
      providesTags: ["Customers"],
    }),
    getTransaction: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: `/transactions/gettransaction`,
        method: "GET",
        params: { page, pageSize, search, sort },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => `/geography/getgeography`,
      providesTags: ["Geography"],
    }),
    getOverallStat: build.query({
      query: () => `/overallstats/getoverallstats`,
      providesTags: ["Sales"],
    }),
    getDashboardStats: build.query({
      query: () => `/dashboard/dashboarstats`,
      providesTags: ["dashboardStats"],
    }),
    getOrders: build.query({
      query: () => `/api/orders`,
      providesTags: ["getAllOrders"],
    }),

    addNewProduct: build.mutation({
      query: (formdeta) => ({
        url: "/api/products/addproduct",
        method: "POST",
        body: formdeta,
      }),
      providesTags: ["Postnewproduct"],
    }),
    loginUser: build.mutation({
      query: (formData) => ({
        url: "/api/auth",
        method: "POST",
        body: formData,
      }),
      providesTags: ["Postnewproduct"],
    }),
    uploadOffer: build.mutation({
      query: (formdata) => ({
        url: `/offers`,
        method: "POST",
        body: formdata,
      }),
      providesTags: ["DeleteProduct"],
    }),

    updateOffer: build.mutation({
      query: ({ _id, offerdata }) => ({
        url: `/api/products/offer/${_id}`,
        method: "PUT",
        body: offerdata,
      }),
      providesTags: ["DeleteProduct"],
    }),
    updateProduct: build.mutation({
      query: ({ _id, formdata }) => ({
        url: `/api/products/update/${_id}`,
        method: "PUT",
        body: formdata,
      }),
      providesTags: ["DeleteProduct"],
    }),
    DeleteProduct: build.mutation({
      query: (_id) => ({
        url: `/api/products/${_id}`,
        method: "DELETE",
      }),
      providesTags: ["DeleteProduct"],
    }),
    DeleteOrder: build.mutation({
      query: ({ orderId }) => ({
        url: `/api/orders/${orderId}`,
        method: "DELETE",
      }),
      providesTags: ["DeleteProduct"],
    }),
    DeleteOffer: build.mutation({
      query: (_id) => ({
        url: `/api/products/offer/${_id}`,
        method: "DELETE",
      }),
      providesTags: ["DeleteProductOffer"],
    }),
    updateStatus: build.mutation({
      query: ({ orderId, status }) => ({
        url: `/api/orders/${orderId}`,
        method: "PUT",
        body: { status },
      }),
      providesTags: ["DeleteProduct"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useGetOfferProductsQuery,
  useGetClientsQuery,
  useGetTransactionQuery,
  useGetGeographyQuery,
  useUpdateStatusMutation,
  useDeleteOrderMutation,
  useDeleteOfferMutation,
  useGetOverallStatQuery,
  useGetDashboardStatsQuery,
  useGetOrdersQuery,
  useDeleteProductMutation,
  useUploadOfferMutation,
  useUpdateOfferMutation,
  useUpdateProductMutation,
  useLoginUserMutation,
  useAddNewProductMutation,
} = api;
