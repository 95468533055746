import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAddNewProductMutation, useGetProductsQuery } from "state/api";
import { toast } from "react-toastify";
let initialState = {
  name: "",
  slug: "",
  color: "",
  images: [],
  price: "",
  category: "",
  countInStock: "",
  generalSpecification: "",
  description: "",
  discountPrice: null,
};

const Form = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState(initialState);
  const [files, setFiles] = useState([]);
  const [categories, setCategories] = useState([
    "iPads",
    "iPhones",
    "Mac",
    "Accessories",
    "Phones & Devices",
    "Audio",
    "TVs",
    "Gaming",
  ]);
  const { data, isLoading } = useGetProductsQuery();

  useEffect(() => {
    if (data) {
      const uniqueCategories = new Set(categories); // Convert existing categories to a Set
      data.forEach((product) => {
        const trimmedCategory = product.category.trim(); // Trim the category data
        uniqueCategories.add(trimmedCategory); // Add the trimmed category to the Set
      });
      const newCategories = Array.from(uniqueCategories); // Convert the Set back to an array
      setCategories(newCategories);
    }
  }, [data, categories]);

  const {
    name,
    slug,
    price,
    color,
    description,
    category,
    countInStock,
    discountPrice,
    generalSpecification,
  } = formData;

  const [addNewProduct] = useAddNewProductMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onAddFiles = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();

    // Append all files to formdata
    files.forEach((file) => {
      formdata.append("images", file);
    });

    // Append other form fields to formdata
    Object.keys(formData).forEach((key) => {
      formdata.append(key, formData[key]);
    });
    formdata && console.log(formdata);

    try {
      const response = await addNewProduct(formdata).unwrap();

      console.log(response);

      if (response.message === "Product added successfully") {
        setFormData(initialState);
        toast.success(response.message);
      } else {
        toast.error("Failed to add product");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add product please try again with less payload");
    }
  };

  const handleAddCategory = () => {
    const newCategory = prompt("Enter new category:");
    if (newCategory) {
      setCategories([...categories, newCategory]);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ height: "fit-content" }}>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Images</InputLabel>
        <input
          type="file"
          name="images"
          onChange={onAddFiles}
          multiple
          required
          style={{
            flex: 2,
            marginTop: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Name</InputLabel>
        <TextField
          type="text"
          name="name"
          value={name}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Price</InputLabel>
        <TextField
          type="text"
          name="price"
          value={price}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Discount Price</InputLabel>
        <TextField
          type="text"
          name="discountPrice"
          value={discountPrice}
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Slug</InputLabel>
        <TextField
          type="text"
          name="slug"
          value={slug}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Category</InputLabel>
        <Select
          value={formData.category}
          name="category"
          onChange={handleInputChange}
          required
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button onClick={handleAddCategory} sx={{ marginLeft: "1rem" }}>
        Add Category
      </Button>

      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Color</InputLabel>
        <TextField
          type="text"
          name="color"
          value={color}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>General Specification</InputLabel>
        <ReactQuill
          theme="snow"
          value={generalSpecification}
          onChange={(value) =>
            setFormData({ ...formData, generalSpecification: value })
          }
          modules={{ toolbar: true }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "link",
            "list",
            "blockquote",
          ]}
          style={{ flex: 2, backgroundColor: theme.palette.background.alt }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Count In Stock</InputLabel>
        <TextField
          name="countInStock"
          type="text"
          value={countInStock}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginTop: "1rem",
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Description</InputLabel>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={(value) => setFormData({ ...formData, description: value })}
          modules={{ toolbar: true }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "link",
            "list",
            "blockquote",
          ]}
          style={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Button
        type="submit"
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.alt,
          fontSize: "14px",
          fontWeight: "bold",
          width: "50%",
          marginX: "auto",
          marginY: "4rem",
          padding: "10px 20px",
        }}
      >
        Post Products
      </Button>
    </form>
  );
};

export default Form;

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   useTheme,
// } from "@mui/material";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// /* import imageCompression from "browser-image-compression";
//  */ import { useAddNewProductMutation, useGetProductsQuery } from "state/api";
// import { toast } from "react-toastify";
// let initialState = {
//   name: "",
//   slug: "",
//   color: "",
//   images: [],
//   price: "",
//   category: "",
//   countInStock: "",
//   generalSpecification: "",
//   description: "",
//   vatinclusive: null,
// };

// const Form = () => {
//   const theme = useTheme();
//   const [formData, setFormData] = useState(initialState);
//   const [images, setImages] = useState([]);
//   const [categories, setCategories] = useState([
//     "iPads",
//     "iPhones",
//     "Mac",
//     "Accessories",
//     "Phones & Devices",
//     "Audio",
//     "TVs",
//     "Gaming",
//   ]);
//   const { data, isLoading } = useGetProductsQuery();

//   useEffect(() => {
//     if (data) {
//       const uniqueCategories = new Set(categories); // Convert existing categories to a Set
//       data.forEach((product) => {
//         const trimmedCategory = product.category.trim(); // Trim the category data
//         uniqueCategories.add(trimmedCategory); // Add the trimmed category to the Set
//       });
//       const newCategories = Array.from(uniqueCategories); // Convert the Set back to an array
//       setCategories(newCategories);
//     }
//   }, []);
//   const {
//     name,
//     slug,
//     price,
//     color,
//     description,
//     category,
//     countInStock,
//     vatinclusive,
//     generalSpecification,
//   } = formData;
//   const [addNewProduct] = useAddNewProductMutation();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setFormData({ ...formData, [name]: value });
//   };
//   const onAddFiles = async (e) => {
//     const files = Array.from(e.target.files);
//     setImages([]);

//     try {
//       const compressedImages = await Promise.all(
//         files.map(async (file) => {
//           const compressedFile = await compressImage(file);
//           return compressedFile;
//         })
//       );

//       compressedImages.forEach((compressedFile) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(compressedFile);
//         reader.onloadend = () => {
//           setImages((oldArray) => [...oldArray, reader.result]);
//         };
//       });
//     } catch (error) {
//       console.error("Error compressing images:", error);
//     }
//   };

//   const compressImage = (file) => {
//     return new Promise((resolve, reject) => {
//       const image = new Image();
//       image.src = URL.createObjectURL(file);
//       image.onload = () => {
//         const canvas = document.createElement("canvas");
//         const ctx = canvas.getContext("2d");
//         canvas.width = image.width;
//         canvas.height = image.height;

//         // Resize image if needed
//         ctx.drawImage(image, 0, 0, image.width, image.height);

//         // Convert canvas to Blob
//         canvas.toBlob(
//           (blob) => {
//             resolve(new File([blob], file.name, { type: file.type }));
//           },
//           file.type,
//           0.6
//         ); // Adjust compression quality here (0.6 is 60%)
//       };
//       image.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };
//   /*   function PreviewFiles(file) {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onloadend = () => {
//       setImage(reader.result);
//     };
//   }
//   const onAddFiles = (e) => {
//     const file = e.target.files[0];
//     setFile(file);
//     PreviewFiles(file);
//   }; */
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const productData = () => ({ ...formData, images: images });
//     const data = productData();
//     try {
//       const response = await addNewProduct(data);
//       console.log(response);
//       if (response.data.message === "Product added successfully") {
//         setFormData(initialState);
//         toast.success(response.data.message);
//       } else {
//         toast.error("Failed to add product");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Failed to add product please try again with less payload");
//     }
//   };
//   const handleAddCategory = () => {
//     const newCategory = prompt("Enter new category:");
//     if (newCategory) {
//       // Assuming categories is your state variable containing the list of categories
//       setCategories([...categories, newCategory]);
//     }
//   };
//   return (
//     <form onSubmit={handleSubmit} style={{ height: "fit-content" }}>
//       <Box display="flex">
//         <InputLabel
//           sx={{
//             flex: 1,
//           }}
//         >
//           Product Images
//         </InputLabel>
//         <input
//           type="file"
//           name="images"
//           onChange={onAddFiles}
//           multiple
//           required
//           sx={{
//             flex: 2,
//             mt: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Product Name</InputLabel>
//         <TextField
//           type="text"
//           name="name"
//           value={name}
//           required
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Product Price</InputLabel>
//         <TextField
//           type="text"
//           name="price"
//           value={price}
//           required
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>VAT Inclusive</InputLabel>
//         <TextField
//           type="text"
//           name="vatinclusive"
//           value={vatinclusive}
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Product Slug</InputLabel>
//         <TextField
//           type="text"
//           name="slug"
//           value={slug}
//           required
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Category</InputLabel>
//         <Select
//           value={formData.category}
//           name="category"
//           onChange={handleInputChange}
//           required
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         >
//           {categories.map((category) => (
//             <MenuItem key={category} value={category}>
//               {category}
//             </MenuItem>
//           ))}
//         </Select>
//       </Box>
//       <Button onClick={handleAddCategory} sx={{ ml: "1rem" }}>
//         Add Category
//       </Button>

//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Color</InputLabel>
//         <TextField
//           type="text"
//           name="color"
//           value={color}
//           required
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mb: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>General Specification</InputLabel>
//         <ReactQuill
//           theme="snow"
//           value={generalSpecification}
//           onChange={(value) =>
//             setFormData({ ...formData, generalSpecification: value })
//           }
//           modules={{ toolbar: true }}
//           formats={[
//             "bold",
//             "italic",
//             "underline",
//             "strike",
//             "link",
//             "list",
//             "blockquote",
//           ]}
//           style={{
//             flex: 2,
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel
//           sx={{
//             flex: 1,
//           }}
//         >
//           Count In Stock
//         </InputLabel>
//         <TextField
//           name="countInStock"
//           type="text"
//           value={countInStock}
//           required
//           onChange={handleInputChange}
//           sx={{
//             flex: 2,
//             mt: "1rem",
//             mb: "1rem",

//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Box display="flex">
//         <InputLabel sx={{ flex: 1 }}>Product Description</InputLabel>
//         <ReactQuill
//           theme="snow"
//           value={description}
//           onChange={(value) => setFormData({ ...formData, description: value })}
//           modules={{ toolbar: true }}
//           formats={[
//             "bold",
//             "italic",
//             "underline",
//             "strike",
//             "link",
//             "list",
//             "blockquote",
//           ]}
//           style={{
//             flex: 2,
//             marginBottom: "1rem",
//             backgroundColor: theme.palette.background.alt,
//           }}
//         />
//       </Box>
//       <Button
//         type="submit"
//         sx={{
//           backgroundColor: theme.palette.secondary.light,
//           color: theme.palette.alt,
//           fontSize: "14px",
//           fontWeight: "bold",
//           width: "50%",
//           mx: "auto",
//           my: "4rem",
//           padding: "10px 20px",
//         }}
//       >
//         Post Products
//       </Button>
//     </form>
//   );
// };

// export default Form;
