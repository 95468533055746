import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Circles } from "react-loader-spinner";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useLoginUserMutation } from "state/api";
const initialState = {
  email: "",
  password: "",
};
const Login = () => {
  const theme = useTheme();

  const [loginUser] = useLoginUserMutation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialState);

  const { email, password } = formData;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(formData);

      if (response.data) {
        const user = response.data;
        console.log(!user.user.isAdmin);
        if (user.user.isAdmin) {
          localStorage.setItem("admin", JSON.stringify(user)); // Stringify user object before saving
          navigate("/add product");
        }
      } else {
        toast.error(response.error.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* useEffect(() => {
    error && toast.error(error.message);
  }, [error]); */

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "4px",
        marginTop: "4rem",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Log in to admins Dashboard here
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          width: isMobile ? "90%" : "50%",
          mx: "auto",
          padding: "10px 20px",
        }}
      >
        <InputLabel sx={{ flex: 1 }}>Email</InputLabel>
        <TextField
          type="email"
          name="email"
          value={email}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            mb: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box
        display="flex"
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          width: isMobile ? "90%" : "50%",
          mx: "auto",
          padding: "10px 20px",
        }}
      >
        <InputLabel sx={{ flex: 1 }}>Password</InputLabel>
        <TextField
          type="password"
          name="password"
          value={password}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            mb: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>

      <Button
        type="submit"
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.alt,
          fontSize: "14px",
          fontWeight: "bold",
          width: "50%",
          mx: "auto",
          my: "4rem",
          padding: "10px 20px",
        }}
      >
        Login
      </Button>
    </form>
  );
};

export default Login;
