import React, { useState } from "react";
import Hearder from "components/Hearder";
import { Box } from "@mui/system";
import { useGetProductsQuery, useDeleteProductMutation } from "../../state/api"; // Import the delete mutation hook
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Fade,
  Backdrop,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import { toast } from "react-toastify";
import Offer from "components/Offer";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
const Product = ({
  data: { _id, name, description, price, images, category, countInStock },
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteProduct] = useDeleteProductMutation(); // Mutation hook for deleting the product

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      // Prompt the user for confirmation
      const confirmed = window.confirm(
        "Are you sure you want to delete this product?"
      );

      // If user confirms, proceed with deletion
      if (confirmed) {
        const response = await deleteProduct(_id);
        toast.success(response?.data?.msg);
      } else {
        // If user cancels deletion, do nothing or provide feedback
        console.log("Deletion cancelled by user");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete product");
    }
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
      }}
    >
      <CardContent>
        Modal Gallery
        <Grid container spacing={1}>
          {images?.map((image, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <div
                style={{
                  position: "relative",
                  width: "100%", // Adjusted to 100% width of parent Grid item
                  height: 0, // Set height to 0 to start with
                  paddingBottom: "100%", // Maintain 1:1 aspect ratio (square)
                  overflow: "hidden", // Hide any overflowing content
                }}
              >
                <img
                  src={`https://nadtech.sokeserver.nadtech.co.ke/Images/${image}`}
                  alt={`Product ${index + 1}`}
                  style={{
                    position: "absolute", // Position image absolutely within the div
                    width: "100%", // Ensure image covers the entire div
                    height: "100%", // Ensure image covers the entire div
                    objectFit: "cover", // Cover the div while maintaining aspect ratio
                    cursor: "pointer",
                  }}
                  onClick={() => handleImageClick(image)}
                />
              </div>
            </Grid>
          ))}
        </Grid>
        <CardActions>
          <Button
            variant="primary"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Show Less" : "See More"}
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            <DeleteIcon /> {/* Delete icon */}
          </Button>
          <Link to={`/edit/${_id}`}>
            <Button variant="outlined" color="primary" size="small">
              <EditIcon />
            </Button>
          </Link>
        </CardActions>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* Additional content to show when expanded */}
          </CardContent>
        </Collapse>
        <Modal
          open={isModalOpen}
          onClose={closeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isModalOpen}>
            <Box>
              {/* Modal Gallery */}
              <img
                src={`https://nadtech.sokeserver.nadtech.co.ke/Images/${selectedImage}`}
                alt="Selected Product"
                style={{ maxWidth: "100%", maxHeight: "100vh" }}
              />
            </Box>
          </Fade>
        </Modal>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {category}
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography sx={{ mv: "1.5rem" }} color={theme.palette.secondary[400]}>
          Ksh.{Number(price).toFixed(2)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Show Less" : "See More"}
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeOut="auto"
        unmountOnExit
        sx={{ color: theme.palette.neutral[300] }}
      >
        <CardContent>
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Typography>id:{_id}</Typography>
          <Typography>Supply Left:{countInStock}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Products = () => {
  const { data, isLoading } = useGetProductsQuery();
  const isNonMobile = useMediaQuery("min-width:600px");

  return (
    <Box m="1.5rem 2.5rem">
      <Hearder title="OFFER" subtitle="see products on Offer" />
      <Box>
        <Offer />
      </Box>
      <Hearder title="PRODUCTS" subtitle="see all your products" />
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4,minmax(0,1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data &&
            data.map((data, index) => <Product data={data} key={index} />)}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Products;
