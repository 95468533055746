import { useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { themeSettings } from "theme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "scenes/dashboard/Dashboard";
import Layout from "scenes/Layout/Layout";
import Products from "scenes/products/Products";
import Clients from "scenes/clients/Clients";
import Addproduct from "scenes/addproduct/Addproduct";
import Orders from "scenes/orders/Orders";
import Login from "scenes/login/Login";
import PrivateRoute from "components/PrivateRoute";
import EditProduct from "scenes/EditProduct/EditProduct";
function App() {
  const { mode } = useSelector((state) => ({ ...state.mode }));
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />}>
              <Route
                path="/"
                element={<Navigate to="/add product" replace />}
              />
              {/*               <Route path="/dashboard" element={<Dashboard />} />
               */}{" "}
              <Route
                path="/add product"
                element={
                  <PrivateRoute>
                    <Addproduct />
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit/:id"
                element={
                  <PrivateRoute>
                    <EditProduct />
                  </PrivateRoute>
                }
              />
              <Route
                path="/products"
                element={
                  <PrivateRoute>
                    <Products />
                  </PrivateRoute>
                }
              />
              <Route
                path="/orders"
                element={
                  <PrivateRoute>
                    <Orders />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              {/*               <Route path="/login" element={<Login />} />
               */}{" "}
              {/*               
              <Route path="/clients" element={<Clients />} />
              <Route path="/transactions" element={<TRansactions />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} /> */}
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
