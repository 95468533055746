import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Hearder from "components/Hearder";
import FlexBetween from "../../components/flex";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetProductQuery, useUpdateProductMutation } from "state/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import UpdateForm from "components/updateForm";

const EditProduct = () => {
  const { id } = useParams();
  const { data: productData, isLoading } = useGetProductQuery(id);
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Hearder title="ADD PRODUCT" subtitle="Add your products Here" />
      </FlexBetween>

      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          marginBottom: 24,
        }}
      >
        {productData ? (
          <UpdateForm {...productData} />
        ) : (
          <p>Loading product details...</p>
        )}
      </Box>
    </Box>
  );
};

export default EditProduct;
