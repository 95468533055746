import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Hearder from "components/Hearder";
import React from "react";
import FlexBetween from "../../components/flex";
import Form from "components/form";

const Addproduct = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Hearder title="ADD PRODUCT" subtitle="Add your products Here" />
      </FlexBetween>

      <Box
        sx={{
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          marginBottom: 24,
        }}
      >
        <Form />
      </Box>
    </Box>
  );
};

export default Addproduct;
