import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useGetProductQuery, useUpdateProductMutation } from "state/api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
let initialState = {
  name: "",
  slug: "",
  color: "",
  images: [],
  price: "",
  category: "",
  countInStock: "",
  generalSpecification: "",
  description: "",
  discountPrice: null,
};

const UpdateForm = ({
  _id,
  name,
  slug,
  color,
  images,
  price,
  category,
  countInStock,
  generalSpecification,
  description,
  discountPrice,
}) => {
  const theme = useTheme();
  /* const {
    name,
    slug,
    color,
    images,
    price,
    category,
    countInStock,
    generalSpecification,
    description,
    vatinclusive,
  } = productData;
 */
  const [formData, setFormData] = useState({
    name: name,
    slug: slug,
    color: color,
    images: images,
    price: price,
    category: category,
    countInStock: countInStock,
    generalSpecification: generalSpecification,
    description: description,
    discountPrice: discountPrice,
  });
  const [files, setFiles] = useState([]);
  const [categories, setCategories] = useState([
    "iPads",
    "iPhones",
    "Mac",
    "Accessories",
    "Phones & Devices",
    "Audio",
    "TVs",
    "Gaming",
  ]);

  const [updateProduct] = useUpdateProductMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onAddFiles = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleAddCategory = () => {
    const newCategory = prompt("Enter new category:");
    if (newCategory) {
      setCategories([...categories, newCategory]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object
    const formdata = new FormData();

    // Append all files to formdata under 'images' key
    files.forEach((file) => {
      formdata.append("images", file);
    });

    // Append other form fields to formdata
    Object.keys(formData).forEach((key) => {
      formdata.append(key, formData[key]);
    });
    formdata && console.log(formdata);

    try {
      // Perform API call to add/update product using formdata
      const response = await updateProduct({ _id, formdata }).unwrap();

      // Handle response based on success or failure
      console.log("Response:", response);

      if (response) {
        // Reset form data to initialState upon successful submission
        setFormData(initialState);
        toast.success("Product updated successfully");
      }
    } catch (error) {
      // Handle error during API call
      console.error("Error:", error);
      toast.error("Failed to update product. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ height: "fit-content" }}>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Images</InputLabel>
        <input
          type="file"
          name="images"
          onChange={onAddFiles}
          multiple
          style={{
            flex: 2,
            marginTop: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Name</InputLabel>
        <TextField
          type="text"
          name="name"
          value={formData.name}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Price</InputLabel>
        <TextField
          type="text"
          name="price"
          value={formData.price}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Discount Price</InputLabel>
        <TextField
          type="text"
          name="discountPrice"
          value={formData.discountPrice}
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Slug</InputLabel>
        <TextField
          type="text"
          name="slug"
          value={formData.slug}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Category</InputLabel>
        <Select
          value={formData.category}
          name="category"
          onChange={handleInputChange}
          required
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button onClick={handleAddCategory} sx={{ marginLeft: "1rem" }}>
        Add Category
      </Button>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Color</InputLabel>
        <TextField
          type="text"
          name="color"
          value={formData.color}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>General Specification</InputLabel>
        <ReactQuill
          theme="snow"
          value={formData.generalSpecification}
          onChange={(value) =>
            setFormData({ ...formData, generalSpecification: value })
          }
          modules={{ toolbar: true }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "link",
            "list",
            "blockquote",
          ]}
          style={{ flex: 2, backgroundColor: theme.palette.background.alt }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Count In Stock</InputLabel>
        <TextField
          name="countInStock"
          type="text"
          value={formData.countInStock}
          required
          onChange={handleInputChange}
          sx={{
            flex: 2,
            marginTop: "1rem",
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Box display="flex">
        <InputLabel sx={{ flex: 1 }}>Product Description</InputLabel>
        <ReactQuill
          theme="snow"
          value={formData.description}
          onChange={(value) => setFormData({ ...formData, description: value })}
          modules={{ toolbar: true }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "link",
            "list",
            "blockquote",
          ]}
          style={{
            flex: 2,
            marginBottom: "1rem",
            backgroundColor: theme.palette.background.alt,
          }}
        />
      </Box>
      <Button
        type="submit"
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.alt,
          fontSize: "14px",
          fontWeight: "bold",
          width: "50%",
          marginX: "auto",
          marginY: "4rem",
          padding: "10px 20px",
        }}
      >
        Update Product
      </Button>
    </form>
  );
};

export default UpdateForm;
