import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Hearder from "components/Hearder";
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useUpdateStatusMutation,
} from "state/api";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { DeleteOutline } from "@mui/icons-material";
import { Button, FormControl, MenuItem, Modal, Select } from "@mui/material";
import { toast } from "react-toastify";
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("Pending");
  const [updateStatus] = useUpdateStatusMutation();
  const [DeleteOrder] = useDeleteOrderMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const timestamp = row?.createdAt;
  const date = new Date(timestamp);

  // Formatting the date in a readable format
  const formattedDate = `${date.toDateString()} ${date.toLocaleTimeString()}`;
  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleUpdateStatus = async () => {
    try {
      console.log({ orderId: row._id, status });
      const response = await updateStatus({ orderId: row._id, status });
      console.log(response);
      if (response.data) {
        toast.success(
          "Order Status Updated please refresh the page to see changes"
        );
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row?._id}</TableCell>
        <TableCell align="right">
          {row?.address?.firstname} {row?.address?.lastname}
        </TableCell>
        <TableCell align="right">{row?.address?.email}</TableCell>
        <TableCell align="right">{row?.address?.phone}</TableCell>
        <TableCell align="right">{formattedDate}</TableCell>
        <TableCell align="right">{row?.status}</TableCell>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", "&:hover": { transform: "scale(1.1)" } }}
          >
            <ModeEditIcon onClick={handleEditClick} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", "&:hover": { transform: "scale(1.1)" } }}
          >
            <DeleteOutline
              onClick={() => {
                toast.success("Order Deleted,refresh the page to see changes");
                DeleteOrder({ orderId: row._id });
              }}
            />
          </Box>
        </Box>
      </TableRow>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-order-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Order Status
          </Typography>
          <FormControl fullWidth>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="status"
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleUpdateStatus}>Update Status</Button>
        </Box>
      </Modal>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Order Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Total price (ksh)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.products.map((historyRow) =>
                    historyRow.map((product) => (
                      <TableRow key={product?.id}>
                        <TableCell component="th" scope="row">
                          {product?.id}
                        </TableCell>
                        <TableCell align="right">
                          {product?.product?.name}
                        </TableCell>
                        <TableCell align="right">
                          {product?.product?.price}
                        </TableCell>
                        <TableCell align="right">{product?.quantity}</TableCell>
                        <TableCell align="right">
                          {product?.itemTotal}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];
const Orders = () => {
  const { data, isLoading } = useGetOrdersQuery();
  console.log(data);
  return (
    <Box m="1.5rem 2.5rem">
      <Hearder title="Orders" subtitle="see all Orders That Have been made" />
      {data || !isLoading ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Order Id</TableCell>
                <TableCell align="right">Client Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Date Ccreated</TableCell>
                <TableCell align="right">Order Status</TableCell>
                <TableCell align="right">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <Row key={row._id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Orders;
