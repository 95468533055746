import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Card, CardActions, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { InputLabel, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

import {
  useGetOfferProductsQuery,
  useUploadOfferMutation,
  useUpdateOfferMutation,
  useDeleteOfferMutation,
} from "state/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
let initialState = {
  name: "",
  images: [],
  price: "",
};
const Product = ({ data: { _id, name, price, image, initialprice } }) => {
  const theme = useTheme();
  const [DeleteOffer] = useDeleteOfferMutation();

  const handleDelete = async () => {
    try {
      // Prompt the user for confirmation
      const confirmed = window.confirm(
        "Are you sure you want to delete this product?"
      );

      // If user confirms, proceed with deletion
      if (confirmed) {
        const response = await DeleteOffer(_id);
        toast.success(response?.data?.msg);
      } else {
        // If user cancels deletion, do nothing or provide feedback
        console.log("Deletion cancelled by user");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete product");
    }
  };
  const discountedPrice = Number(price).toFixed(2);
  const originalPrice = Number(initialprice).toFixed(2);
  const percentageOff =
    ((originalPrice - discountedPrice) / originalPrice) * 100;
  const roundedPercentageOff = percentageOff.toFixed(0);
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        display: "flex",
        "@media (max-width: 768px)": {
          flexDirection: "column", // Adjust as per your tablet breakpoint
        },
      }}
    >
      <CardContent>
        <img
          src={`https://nadtech.sokeserver.nadtech.co.ke/Images/${image}`}
          alt={`${name}`}
          style={{ width: "100%", cursor: "pointer" }}
        />

        <CardActions>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleDelete}
          >
            <EditIcon /> {/* Delete icon */}
          </Button>
        </CardActions>

        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="h5" component="div">
          {_id}
        </Typography>
        <Typography sx={{ mv: "1.5rem" }} color={theme.palette.secondary[400]}>
          Ksh.{discountedPrice}
        </Typography>
        <Typography
          sx={{ mv: "1.5rem", textDecoration: "line-through" }}
          color={theme.palette.secondary[400]}
        >
          Ksh.{originalPrice}
        </Typography>
        <Typography sx={{ mv: "1.5rem" }} color={theme.palette.secondary[400]}>
          {roundedPercentageOff}%
        </Typography>
      </CardContent>
    </Card>
  );
};
const Offer = () => {
  const theme = useTheme();
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [offerdata, setOfferdata] = useState({
    price: "",
    name: "",
    initialprice: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /*   const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const { name, price } = formData; */
  const [uploadOffer] = useUploadOfferMutation();
  const [updateOffer] = useUpdateOfferMutation();
  const { data, error, isLoading } = useGetOfferProductsQuery();
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setOfferdata({ ...offerdata, [name]: value });
  };
  const { name, initialPrice, price } = offerdata;
  const upload = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    file && formdata.append("file", file);
    const response = await uploadOffer(formdata);
    const resdata = response.data;
    const { _id } = resdata;
    if (_id) {
      const res = await updateOffer({ _id, offerdata });
      toast.success(res?.data?.msg);
    }
  };
  return (
    <div>
      <Button onClick={handleOpen}>Add product on offer</Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={upload} style={{ height: "fit-content" }}>
            <Box display="flex">
              <InputLabel
                sx={{
                  flex: 1,
                }}
              >
                Image
              </InputLabel>
              <input
                type="file"
                multiple
                required
                onChange={(e) => setFile(e.target.files[0])}
                sx={{
                  flex: 2,
                  mt: "1rem",
                  mb: "1rem",
                  backgroundColor: theme.palette.background.alt,
                }}
              />
            </Box>
            <Box display="flex">
              <InputLabel sx={{ flex: 1 }}>Product Price</InputLabel>
              <TextField
                type="text"
                name="price"
                value={price}
                required
                onChange={handleInputChange}
                sx={{
                  flex: 2,
                  mb: "1rem",
                  backgroundColor: theme.palette.background.alt,
                }}
              />
            </Box>
            <Box display="flex">
              <InputLabel sx={{ flex: 1 }}>Initial Price</InputLabel>
              <TextField
                type="text"
                name="initialprice"
                value={initialPrice}
                required
                onChange={handleInputChange}
                sx={{
                  flex: 2,
                  mb: "1rem",
                  backgroundColor: theme.palette.background.alt,
                }}
              />
            </Box>
            <Box display="flex">
              <InputLabel sx={{ flex: 1 }}>Product Name</InputLabel>
              <TextField
                type="text"
                name="name"
                value={name}
                onChange={handleInputChange}
                required
                sx={{
                  flex: 2,
                  mb: "1rem",
                  backgroundColor: theme.palette.background.alt,
                }}
              />
            </Box>
            <Button
              type="submit"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.alt,
                fontSize: "14px",
                fontWeight: "bold",
                width: "50%",
                mx: "auto",
                my: "4rem",
                padding: "10px 20px",
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4,minmax(0,1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
        >
          {data &&
            data.map((data, index) => <Product data={data} key={index} />)}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default Offer;
